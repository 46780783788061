<template>
  <div>
    <i class="openDrawerButton el-icon-arrow-left" @click="drawer = true"></i>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/mitarbeiter' }">
        <app-i18n code="entities.mitarbeiter.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        {{ mitarbeiterFullName }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="app-content-page" v-loading="showSpinner">
      <el-row :gutter="10">
        <el-col :span="22">
          <app-mitarbeiter-form
            :isEditing="isEditing"
            :record="record"
            :saveLoading="showSpinner"
            :isInactive="isInactive"
            :viewMode="viewMode"
            @cancel="doCancel"
            @submit="doSubmit"
            @setMutterschutz="doSetMutterschutz"
            @updateMutterschutz="doUpdateMutterschutz"
            @setMutterkarenz="doSetMutterkarenz"
            @updateMutterkarenz="doUpdateMutterkarenz"
            @setBildungskarenz="doSetBildungskarenz"
            @updateBildungskarenz="doUpdateBildungskarenz"
            @setPapamonat="doSetPapamonat"
            @updatePapamonat="doUpdatePapamonat"
            @removePapamonat="doRemovePapamonat"
            @submitKarenzvertretung="doSubmitKarenzvertretung"
            v-if="!findLoading"
            ref="form"
          />
        </el-col>
        <el-col :span="2" :offset="0">
          <div class="actions box-card">
            <!-- <el-card shadow="never">
              <div slot="header">
                <span>Aktionen</span>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="doShowVertragDialog()"
                  :disabled="
                    !isEditing || !hasPermissionToCreateVertrag || isInactive
                  "
                >
                  Neu Vertrag
                </el-button>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="doShowVertragsergaenzungDialog()"
                  :disabled="
                    !isEditing || !hasPermissionToCreateVertrag || isInactive
                  "
                >
                  Vertragsergänzung
                </el-button>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="doShowVertragsaenderungDialog()"
                  :disabled="
                    !isEditing || !hasPermissionToCreateVertrag || isInactive
                  "
                >
                  Vertragsänderung
                </el-button>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="doShowBestaetigungDialog()"
                  :disabled="
                    !isEditing ||
                      !hasPermissionToCreateBestaetigung ||
                      isInactive
                  "
                >
                  Bestätigung
                </el-button>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="doShowBeendigungDialog()"
                  :disabled="
                    !isEditing || !hasPermissionToCreateBeendigung || isInactive
                  "
                >
                  Kündigung
                </el-button>
              </div>
              <div class="div-btn">
                <el-button
                  type="text"
                  @click="dialogAbmeldungVisible = true"
                  :disabled="
                    !isEditing || !hasPermissionToAbmelden || isInactive
                  "
                >
                  Abmelden
                </el-button>
              </div>
            </el-card> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <div>
      <portal to="modal">
        <app-bestaetigung-portal
          :id="id"
          :visible="dialogBestaetigungVisible"
          @close="onModalClose"
          @success="onModalClose"
        ></app-bestaetigung-portal>
        <app-abmeldung-form-modal
          :visible="dialogAbmeldungVisible"
          @close="dialogAbmeldungVisible = false"
          @doAbmelden="doAbmelden"
        ></app-abmeldung-form-modal>
      </portal>
    </div>
    <div style="position: relative; with: 100%">
      <el-drawer
        title="Notiz"
        :visible.sync="drawer"
        :with-header="true"
        style="overflow: auto"
        v-if="id"
      >
        <app-mitarbeiter-notiz
          :records="notiz"
          @doCreateNotiz="createNotiz"
          :mitarbeiter="id"
        ></app-mitarbeiter-notiz>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import MitarbeiterForm from '@/modules/mitarbeiter/components/mitarbeiter-form';
import MitarbeiterNotiz from '@/modules/mitarbeiter/components/mitarbeiter-notiz.vue';
import BestaetigungPortal from '@/modules/bestaetigung/components/bestaetigung-portal.vue';
import AbmeldungModal from '@/modules/abmeldung/abmeldung-form-modal.vue';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import { routerAsync } from '@/app-module';
export default {
  name: 'app-mitarbeiter-form-view-page',
  props: ['id'],
  components: {
    [MitarbeiterForm.name]: MitarbeiterForm,
    [MitarbeiterNotiz.name]: MitarbeiterNotiz,
    [BestaetigungPortal.name]: BestaetigungPortal,
    [AbmeldungModal.name]: AbmeldungModal,
  },
  data() {
    return {
      isSaving: false,
      drawer: false,
      dialogBestaetigungVisible: false,
      dialogAbmeldungVisible: false,
    };
  },
  async created() {
    await this.doFind(this.id);
    this.doGetAnstellungHistory();
    /*     this.doFetchNotiz(this.id); */
    this.doSetFilterAndFetchVertrag();
    this.doSetFilterAndFetchVertragsergaenzung();
    this.doSetFilterAndFetchVertragsaenderung();
    this.doSetFilterAndFetchBeendigung();
    await this.$store.dispatch('mitarbeiter/form/doSetViewMode', true);
  },
  computed: {
    ...mapGetters({
      record: 'mitarbeiter/form/record',
      findLoading: 'mitarbeiter/form/findLoading',
      saveLoading: 'mitarbeiter/form/saveLoading',
      currentUser: 'auth/currentUser',
      showSpinner: 'mitarbeiter/form/showSpinner',
      filter: 'mitarbeiter/list/filter',
      pendingFiles: 'mitarbeiter/form/pendingFiles',
      notiz: 'mitarbeiter/form/notiz',
      isInactive: 'mitarbeiter/form/isInactive',
      viewMode: 'mitarbeiter/form/viewMode',
      hasPermissionToCreateBeendigung: 'beendigung/hasPermissionToCreate',
      hasPermissionToCreateVertrag: 'vertrag/hasPermissionToCreate',
      hasPermissionToCreateBestaetigung: 'bestaetigung/hasPermissionToCreate',
    }),
    isEditing() {
      return !!this.id;
    },
    mitarbeiterFullName() {
      return this.record ? this.record.vollname : '';
    },
    hasPermissionToSendPayrollRegistration() {
      return new MitarbeiterPermissions(this.currentUser).edit;
    },
    hasPermissionToAbmelden() {
      return new MitarbeiterPermissions(this.currentUser).abmelden;
    },
    filter() {
      return {
        filter: {
          id: this.id,
        },
      };
    },
    isInactive() {
      return this.record.status === 'inactive';
    },
  },
  methods: {
    ...mapActions({
      doFind: 'mitarbeiter/form/doFind',
      doNew: 'mitarbeiter/form/doNew',
      doUpdate: 'mitarbeiter/form/doUpdate',
      doCreate: 'mitarbeiter/form/doCreate',
      doAbmeldenStore: 'mitarbeiter/form/doAbmelden',
      doSetMutterschutzStore: 'mitarbeiter/form/doSetMutterschutz',
      doUpdateMutterschutzStore: 'mitarbeiter/form/doUpdateMutterschutz',
      doSetMutterkarenzStore: 'mitarbeiter/form/doSetMutterkarenz',
      doUpdateMutterkarenzStore: 'mitarbeiter/form/doUpdateMutterkarenz',
      doSetBildungskarenzStore: 'mitarbeiter/form/doSetBildungskarenz',
      doUpdateBildungskarenzStore: 'mitarbeiter/form/doUpdateBildungskarenz',
      doSetPapamonatStore: 'mitarbeiter/form/doSetPapamonat',
      doUpdatePapamonatStore: 'mitarbeiter/form/doUpdatePapamonat',
      doRemovePapamonatStore: 'mitarbeiter/form/doRemovePapamonat',
      doSetKarenzvertretung: 'mitarbeiter/form/doSetKarenzvertretung',
      doResetVertrag: 'vertrag/doReset',
      doResetBeendigung: 'beendigung/doReset',
      doFetchHistory: 'mitarbeiter/history/doFetch',
      doFetchNotiz: 'mitarbeiter/form/doFetchNotiz',
      doCreateNotiz: 'mitarbeiter/form/doCreateNotiz',
      doFetchVertrag: 'vertrag/doFetch',
      doFetchVertragsergaenzung: 'vertragsergaenzung/doFetch',
      doFetchVertragsaenderung: 'vertragsaenderung/doFetch',
      doFetchBeendigung: 'beendigung/doFetch',
    }),
    doCancel() {
      routerAsync().push('/mitarbeiter');
    },
    async doSubmit(payload) {
      this.isSaving = true;
      if (this.isEditing) {
        await this.doUpdate(payload);
      } else {
        await this.doCreate(payload.values);
      }
      this.isSaving = false;
    },
    async doAbmelden(payload) {
      await this.doAbmeldenStore({ id: this.id, values: payload });
      this.dialogAbmeldungVisible = false;
    },
    async doGetAnstellungHistory() {
      return this.doFetchHistory(this.filter);
    },
    async doSetFilterAndFetchVertrag() {
      return this.doFetchVertrag(this.filter);
    },
    async doSetFilterAndFetchVertragsergaenzung() {
      return this.doFetchVertragsergaenzung(this.filter);
    },
    async doSetFilterAndFetchVertragsaenderung() {
      return this.doFetchVertragsaenderung(this.filter);
    },
    async doSetFilterAndFetchBeendigung() {
      return this.doFetchBeendigung(this.filter);
    },
    async createNotiz(payload) {
      try {
        this.doCreateNotiz(payload);
      } catch (error) {
        return;
      }
    },
    doShowVertragsergaenzungDialog() {
      this.$store.dispatch('vertragsergaenzung/doShowFormDialog');
    },
    doShowVertragsaenderungDialog() {
      this.$store.dispatch('vertragsaenderung/doShowFormDialog');
    },
    doShowVertragDialog() {
      this.$store.dispatch('vertrag/doShowFormDialog');
    },
    doShowBeendigungDialog() {
      this.$store.dispatch('beendigung/doShowFormDialog');
    },
    doShowBestaetigungDialog() {
      this.dialogBestaetigungVisible = true;
    },
    onModalClose() {
      this.dialogBestaetigungVisible = false;
    },
    doShowAbmeldungDialog() {
      this.dialogAbmeldungVisible = true;
    },
    /* ---------------------------------------KARENZ------------------------------------- */
    async doSetMutterschutz(payload) {
      this.isSaving = true;
      await this.doSetMutterschutzStore(payload);
      this.isSaving = false;
    },
    async doUpdateMutterschutz(payload) {
      this.isSaving = true;
      await this.doUpdateMutterschutzStore(payload);
      this.isSaving = false;
    },
    async doSetMutterkarenz(payload) {
      this.isSaving = true;
      await this.doSetMutterkarenzStore(payload);
      this.isSaving = false;
    },
    async doUpdateMutterkarenz(payload) {
      this.isSaving = true;
      await this.doUpdateMutterkarenzStore(payload);
      this.isSaving = false;
    },
    async doSetBildungskarenz(payload) {
      this.isSaving = true;
      await this.doSetBildungskarenzStore(payload);
      this.isSaving = false;
    },
    async doUpdateBildungskarenz(payload) {
      this.isSaving = true;
      await this.doUpdateBildungskarenzStore(payload);
      this.isSaving = false;
    },
    async doSubmitKarenzvertretung(payload) {
      this.isSaving = true;
      await this.doSetKarenzvertretung(payload);
      this.isSaving = false;
    },
    async doSetPapamonat(payload) {
      this.isSaving = true;
      await this.doSetPapamonatStore(payload);
      this.isSaving = false;
    },
    async doUpdatePapamonat(payload) {
      this.isSaving = true;
      await this.doUpdatePapamonatStore(payload);
      this.isSaving = false;
    },
    async doRemovePapamonat(payload) {
      this.isSaving = true;
      await this.doRemovePapamonatStore(payload);
      this.isSaving = false;
    },
    /* ---------------------------------------KARENZ------------------------------------- */
  },
};
</script>
<style scoped>
.openDrawerButton {
  float: right;
}
</style>
